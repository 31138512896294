<template>
  <div class="merchantcenter_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.feedbackinformation }}</div>

      <div class="input_box" style="align-items: center">
        <div
          :style="
            languagetype == 'EN'
              ? 'min-width: 155px;white-space: nowrap'
              : 'min-width: 100px;white-space: nowrap'
          "
        >
          {{ language.FeedbackTitle }}
        </div>
        <div>
          <el-input v-model="title" :placeholder="language.FeedbackTitle"></el-input>
        </div>
      </div>
      <div class="input_box">
        <div
          :style="
            languagetype == 'EN'
              ? 'min-width: 155px;white-space: nowrap'
              : 'min-width: 100px;white-space: nowrap'
          "
        >
          {{ language.FeedbackContent }}
        </div>
        <div
          style="
            height: 500px;
            display: flex;
            flex-direction: column;
            border: 1px solid #ccc;
          "
        >
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="flex: 1; overflow-y: scroll"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
          />
        </div>
      </div>
      <div
        style="width: 1000px; display: flex; justify-content: center; margin-top: 20px"
      >
        <el-button type="primary" v-show="submittoshow" @click="submitto()">{{
          language.submitto
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<script>
import { feedbacksave, commonuploadBase64 } from "../../api/index";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { i18nAddResources, i18nChangeLanguage } from "@wangeditor/editor";
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      languagetype: "",
      language: "",
      title: "",
      submittoshow: true,
      editor: null,
      html: "",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            customUpload: this.update,
          },
          // 继续其他菜单配置...
        },
      },
      mode: "default", // or 'simple'
    };
  },
  created() {
    // 判断语言
    this.changelanguage();
  },
  watch: {
    // 判断语言
    "$store.state.language"() {
      this.changelanguage();
    },
  },
  methods: {
    update(e, insertFn) {
      this.getBase64(e).then((res) => {
        let data = {
          file: res,
        };
        if (e) {
          res = res.split(",")[1].split("=")[0];
          var strLength = res.length;
          var fileLength = ((strLength - (strLength / 8) * 2) / 1024).toFixed(2);
          console.log(fileLength);
          if (fileLength > 1024) {
            this.$message({
              message: this.language.Uploadingimagesmustbelessthan1M,
              type: "warning",
            });
            return;
          }
        }
        commonuploadBase64(data).then((res2) => {
          insertFn(res2.data.url, e.name, res2.data.url);
        });
      });
    },
    // 转换
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    // 提交信息
    submitto() {
      let data = {
        title: this.title,
        content: this.html,
      };
      this.submittoshow = false;
      feedbacksave(data).then((res) => {
        if (res.code == 1) {
          this.title = "";
          this.html = "";
          this.$message({
            message: this.language.Submittedsuccessfully,
            type: "success",
            duration: 2000,
          });
        }
        this.submittoshow = true;
      });
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
      if (this.languagetype == "EN") {
        console.log(i18nChangeLanguage);
        i18nChangeLanguage("en");
      } else if (this.languagetype == "CHS") {
        i18nChangeLanguage("zh-CN");
      } else if (this.languagetype == "TC") {
        i18nAddResources("hk", {
          // 标题
          header: {
            title: "文字",
            text: "正文",
          },
          tableModule: {
            deleteCol: "刪除檔列",
            deleteTable: "删除行",
            widthAuto: "適應寬度",
            insertCol: "插入列",
            insertRow: "插入線條",
            insertTable: "插入錶",
            header: "鐘錶",
          },
          listModule: {
            unOrderedList: "無序列表",
            orderedList: "有序列表",
          },
          videoModule: {
            delete: "删除視頻",
            uploadVideo: "上傳視頻",
            insertVideo: "插入視頻",
            videoSrc: "地址視頻",
            videoSrcPlaceHolder: "視頻URL或協力廠商<iframe>",
            videoPoster: "視頻封面",
            videoPosterPlaceHolder: "URL封面圖片",
            ok: "定義",
            editSize: "調整大小",
            width: "寬",
            height: "高度",
          },
          textStyle: {
            bold: "粗體",
            underline: "下劃線",
            italic: "斜體",
            through: "删除行",
            code: "內部程式碼",
            sup: "上標",
            sub: "下標",
            clear: "删除格式",
          },
          blockQuote: {
            title: "應用",
          },
          color: {
            default: "默認顏色",
            color: "文字顏色",
            bgColor: "背景顏色",
            clear: "清除背景顏色",
          },
          fontSize: {
            default: "默認字元",
            title: "號碼",
          },
          fontFamily: {
            default: "預設字體",
            title: "字體",
          },
          lineHeight: {
            default: "默認行高度",
            title: "行高",
          },
          todo: {
            todo: "代辦",
          },
          justify: {
            left: "左對齊",
            right: "右對齊",
            center: "中心對齊",
            justify: "兩端對齊",
          },
          indent: {
            increase: "新增縮進",
            decrease: "减少縮進",
          },
          emotion: {
            title: "表情",
          },
          link: {
            insert: "上傳圖片",
          },
          image: {
            netImage: "網絡照片",
          },
          uploadImgModule: {
            uploadImage: "加載影像",
          },
          codeBlock: {
            title: "代碼塊",
          },
          common: {
            enter: "回來",
          },
          highLightModule: {
            selectLang: "語言選擇",
          },
          divider: {
            title: "分界線",
          },
          undo: {
            undo: "取消",
            redo: "重做",
          },
          fullScreen: {
            title: "全屏",
          },
        });
        i18nChangeLanguage("hk");
        // zh-hk
      }
    },
  },
};
</script>
<style scoped>
.merchantcenter_page {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.page_title {
  font-size: 26px;
  font-weight: 600;
}
.input_box {
  margin-right: 30px;
  margin-top: 10px;
  display: flex;
  width: 1000px;
}
.w-e-text-container > .w-e-scroll {
  overflow-y: inherit !important;
}

.w-e-bar-item:nth-of-type(24) {
  display: none;
}
* /deep/ .w-e-bar-item:nth-of-type(24) {
  display: none;
}
* /deep/ .w-e-bar-item:nth-of-type(21) {
  display: none;
}
* /deep/ .w-e-bar-divider {
  display: none;
}
.w-e-bar-item:nth-of-type(23) {
  display: none;
}
</style>
